<template>
  <v-card
    :loading="loading"
    height="100%"
    flat
    tile
  >
    <v-row v-if="!loading || loaded">
      <v-col class="config-bar overflow-y-auto columns">
        <TemplateConfig
          :fields="fields"
          :loading="generatingPreview"
          @generate-preview="generatePreview()"
        />
      </v-col>

      <v-col class="template-container overflow-y-auto columns" style="width: 100px">
        <v-row class="d-inline-flex flex-wrap flex-lg-nowrap px-2 justify-space-between">
          <v-col class="flex-grow-1 flex-lg-grow-0 order-lg-1 order-1 px-2 px-lg-0">
            <div class="d-inline-flex text-h6 font-weight-medium">
              <v-icon
                color="defaultIconColor"
                class="icon-title mr-2"
                dense
              >{{ titleIcon }}</v-icon>
              <div style="width: max-content">{{ $t('Self-service Template') }}</div>
            </div>
          </v-col>

          <v-col class="flex-grow-0 pl-3 pr-1 order-lg-2 d-none d-lg-block">
            <v-divider vertical> </v-divider>
          </v-col>

          <v-col class="flex-grow-1 order-lg-3 order-3 px-0">
            <Breadcrumbs :items="formattedBreadcrumbs" maxWidthItem="45%" />
          </v-col>
        </v-row>

        <TemplateExport
          type="html"
          :templates="htmlTemplates"
          :selected-node-ids="selectedNodeIds"
          :data-provider="dataProvider"
          :jst-node-id="jstNodeId"
          :jst-config="data"
          @toggleSelectedTemplate="toggleSelectedTemplate"
          @templatesExported="refreshHistory"
        />

        <TemplateExport
          type="video"
          :templates="videoTemplates"
          :selected-node-ids="selectedNodeIds"
          :data-provider="dataProvider"
          :jst-node-id="jstNodeId"
          :jst-config="data"
          @toggleSelectedTemplate="toggleSelectedTemplate"
          @templatesExported="refreshHistory"
        />

      </v-col>

      <v-col class="history-bar overflow-y-auto columns">
        <h3>
          {{ $t('History') }}
          <span
            class="float-right"
            v-if="loadingHistorical"
          >
            <v-progress-circular
              :size="18"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </span>
        </h3>

        <TemplateHistorical
          :history="groupedHistory"
          @historySelected="selectHistory"
        />
      </v-col>
    </v-row>
  </v-card>
</template>

<style scoped>
  .row {
    margin: 0px;
  }

  .card-title {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .card-selected {
    background-color: white;
  }

  .title-icon {
    margin-right: 10px;
    color: var(--v-primary-secondary);
  }

  .columns {
    height: 93vh;
  }

  .columns::-webkit-scrollbar {
    display: none;
  }

  .config-bar {
    background-color: white;
    width: 345px;
    max-width: 345px;
  }

  .template-container {
    background-color: #F5F5F5;
  }

  .history-bar {
    background-color: #E6E6E6;
    width: 298px;
    max-width: 298px;
  }

  .history-bar h3 {
    font-size: 20px;
    color: var(--v-primary-secondary);
    font-weight: 500;
    padding: 20px 10px;
  }
</style>

<script>

import Vue from 'vue'
import { mapActions, mapState } from 'vuex'
import AppActions from '@/store/app/actions-types'
import EntryActions from '@/store/content/entry/actions-types'
import SelfServiceTemplateActions from '@/store/content/self_service_template/actions-types'
import TemplateConfig from '@/components/content/self_service_template/TemplateConfig'
import TemplateExport from '@/components/content/self_service_template/TemplateExport'
import TemplateHistorical from '@/components/content/self_service_template/TemplateHistorical'
import Breadcrumbs from "@/components/Breadcrumbs.vue";

export default {
  name: 'EditJst',

  components: {
    Breadcrumbs,
    TemplateConfig,
    TemplateExport,
    TemplateHistorical
  },

  data() {
    return {
      entryData: {},
      data: {},
      loading: true,
      loaded: false,
      loadingHistorical: false,
      generatingPreview: false,
      breadcrumb: [],
      renderedTemplates: [],
      selectedNodeIds: [],
      groupedHistory: {}
    }
  },

  mounted() {
    this.fetchData()
  },

  computed: {
    ...mapState({
      titleIcon: state => state
        .content
        .actions
        .nodeCardActions
        .find(action => action.key == 'edit_self_service_template').icon
    }),

    formattedBreadcrumbs() {
      let formatted = [
        {
          prependIcon: 'mdi-home-outline',
          iconColor: '#757575',
          to: {
            name: 'ContentHome',
            params: { appId: this.$route.params.appId }
          },
          link: true,
          disabled: false,
          ripple: true
        },
      ]

      const reversed = [].concat(this.breadcrumb).reverse()

      reversed.forEach((element, index, array) => {
        formatted.push({
          to: {
            name: 'ContentNavigation',
            params: { parentId: element.node_id }
          },
          text: element.name,
          ripple: true,
          disabled: Object.is(array.length - 1, index),
        })
      })

      return formatted
    },

    fields() {
      if (!this.data || !this.data.form) {
        return []
      }

      return this.data.form.map(field => {
        if (field.type == 'image') {
          field.type = 'select-file'
          field.icon = 'mdi-image-outline'
          field.accepts = 'image/*'
          field.selectText = this.$t('select an image')
          field.modalTitle = this.$t('Select an image')
          field.parentNodeId = field.folder_parent_id || this.entryData.folder_parent_id
        }

        return field
      })
    },

    htmlTemplates() {
      return this
        .renderedTemplates
        .filter((item) => {
          return  item.data_render_type == 'mustache'
            || item.data_render_type == 'gwd'
            || item.data_render_type == 'id'
            || item.data_render_type == 'svg'
        })
    },

    videoTemplates() {
      return this
        .renderedTemplates
        .filter((item) => {
          return item.dataRenderType == 'video' || item.data_render_type == 'video'
          || item.dataRenderType == 'blender' || item.data_render_type == 'blender'
        })
    },

    dataProvider() {
      const dataProvider = {}

      this.fields.forEach(field => {
        dataProvider[field.name || field.label] = field.value
      })

      return dataProvider
    },

    jstNodeId() {
      return this.$route.params.nodeId
    }
   },

  methods: {
    ...mapActions('app', [AppActions.OPEN_APP_SUCCESS_MESSAGE]),

    ...mapActions('content/entry', [
      EntryActions.GET_ENTRY_DATA,
      EntryActions.LIST_SUBFOLDER
    ]),

    ...mapActions('content/self_service_template', [
      SelfServiceTemplateActions.GET_SELF_SERVICE_TEMPLATE,
      SelfServiceTemplateActions.POST_GENERATE_PREVIEW,
      SelfServiceTemplateActions.GET_HISTORY,
      SelfServiceTemplateActions.GET_HISTORICAL_DATA
    ]),

    fetchData() {
      const getEntryData = this[EntryActions.GET_ENTRY_DATA](this.jstNodeId)
      getEntryData.then((result) => {
        this.entryData = result
      })

      const listSubfolders = this[EntryActions.LIST_SUBFOLDER]({parentId: this.jstNodeId})

      listSubfolders.then((result) => {
        this.breadcrumb = result.breadcrumb
      })

      const getJstContent = this[SelfServiceTemplateActions.GET_SELF_SERVICE_TEMPLATE](this.jstNodeId)

      getJstContent.then((result) => {
        this.data = result
      })

      this.refreshHistory()

      Promise.all([getEntryData, listSubfolders, getJstContent])
        .then(() => {
          this.loading = false
          if (!this.loaded) this.loaded = true
        })
        .catch(() => this.loading = false)
    },

    generatePreview() {
      const templates = this.data.config.FilesToRender.map(fileToRender => fileToRender.template_path)  

      const data = {
        templates: templates,
        data_provider: this.dataProvider
      }

      this.renderedTemplates = []
      this.selectedNodeIds = []
      this.generatingPreview = true

      this[SelfServiceTemplateActions.POST_GENERATE_PREVIEW]({nodeId: this.jstNodeId, data: data})
        .then((result) => {
          result.forEach(item => item.selected = false)
          this.renderedTemplates = result
          this.generatingPreview = false

          this.refreshHistory()
        })
        .catch(() => {
          this.generatingPreview = false
        })
    },

    refreshHistory() {
      this.loadingHistorical = true
      this[SelfServiceTemplateActions.GET_HISTORY](this.jstNodeId)
        .then((result) => {
          this.groupedHistory = Object.groupBy(result, ({ generation_id }) => generation_id)
          this.history = result
          this.loadingHistorical = false
        })
        .catch(() => {
          this.loadingHistorical = false
        })
    },

    toggleSelectedTemplate(template) {
      if (this.selectedNodeIds.includes(template.node_id)) {
        this.selectedNodeIds = this.selectedNodeIds.filter(item => item !== template.node_id)
        return
      }

      this.selectedNodeIds.push(template.node_id)
    },

    selectHistory(generationId) {
      this.renderedTemplates = this.groupedHistory[generationId]

      this.loading = true

      this[SelfServiceTemplateActions.GET_HISTORICAL_DATA]({jstNodeId: this.jstNodeId, generationId: generationId})
        .then((result) => {
          this.renderedTemplates = result

          const systemFields = this.renderedTemplates[0].system_fields

          this.fields.forEach((field) => {
            const fieldName = 'template_data_provider.' + field.name

            if (field.type == 'image') {
              Vue.set(field.value, null)
            }

            if (fieldName in systemFields) {
              field.value = systemFields[fieldName]
            }
          })

          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    }
  }
}

</script>
